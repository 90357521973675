body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-tooltip {
  max-width: 400px !important;
}

.ant-table .ant-table-thead tr th {
  color: #fff;
  background: #21b466 !important;
  font-weight: 800;
}

.ant-table-thead tr th:hover, .ant-table-thead tr th:hover .ant-dropdown-trigger {
  background: #31c476 !important;
}

.ant-table-thead .anticon {
  color: #fff;
}
