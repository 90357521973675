.welcome-message {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    z-index: 88;
    justify-content: center;
    // align-items: center;
    font-size: 15rem;
    font-weight: bold;
    color: var(--global--color-brand);
    animation-name: fadeInAndOut;
    animation-duration: 4s;
    opacity: 0;
}

@keyframes fadeInAndOut {
    0% {
        opacity: 0;
        color: var(--global--color-brand);
    }
    50% {
        opacity: 1;
        color: #ecae15;
    }
    100% {
        color: var(--global--color-brand);
        opacity: 0;
    }
}