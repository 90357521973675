.rdw-editor-main {
  border: 1px solid #f1f1f1;
  padding-left: 10px;
  min-height: 342px;
}
.advertisement_text {
  border: 1px solid #f1f1f1;
  padding-left: 20px;
  padding-right: 20px;
  min-height: 342px;
  padding-top: 30px;
}
.task_add_button {
  color: #21B466;
  text-decoration: underline;
  cursor: pointer;
}
.job_tasks_container {
  width: 100% !important;
  padding-bottom: 10px;
  .ant-space-item:first-child {
    width: 100% !important;
  }
}
