.redButton {
  color: #d4380d !important;
  border-color: #d4380d !important;
}

.yellowButton {
  color: #efb012 !important;
  border-color: #efb012 !important;
}

.yellowButton::before {
  background-color: #efb012 !important;
}

.greenButton {
  color: #28b467 !important;
  border-color: #28b467 !important;
}

.greenButton::before {
  background-color: #28b467 !important;
}

.buttonGroup {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
